<!-- src/views/Home.vue -->
<template>
  <div class="session session2">
    <el-space :direction="spaceDirection" alignment="center" :size="10">
      <div class="section-font">
        <h1>MeMefun Vision</h1>
        <ul>
          <li>
            Pump.fun earns million of dollars in fee every day, but the users &
            communities do not benefit from it.
          </li>
          <li>We believe this should change.</li>
          <li>
            MeMefun, where we will distribute the profits to the platform’s
            token holders.
          </li>
        </ul>
      </div>
      <div class="section-img">
        <el-image :src="sectionImg" fit="cover"></el-image>
      </div>
    </el-space>
  </div>
</template>
<script setup lang="ts">
// 判断屏幕宽度
import { useWindowSize } from "./useWindowSize";
const { width } = useWindowSize();
const spaceDirection = width.value >= 991 ? "horizontal" : "vertical";

import sectionImg from "@/assets/memeimg.png";
</script>
<style lang="scss" scoped>
.session2 {
  background-color: var(--background-3-color);
  color: var(--text-3-color);
  min-height: 33vh;

  .section-font {
    padding: 2rem 0;
    width: 50vw;

    li::before {
      color: var(--text-3-color); /* 设置小圆点的颜色为白色 */
    }
  }

  .section-img {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

@media only screen and (max-width: 991px) {
  .session2 {
    .section-font {
      width: 100vw;
    }

    .section-img {
      width: 90vw;
    }
  }
}
</style>

<template>
  <div class="session session6">
    <div class="session-font">
      <h1>Platform fees</h1>
      <el-row>
        <el-col :span="24" class="hidden-md-and-up">
          <ul>
            <li v-for="(item, index) in listItems" :key="index">
              {{ item.value }}
              <span>{{ item.label }}</span>
            </li>
          </ul>
        </el-col>
        <el-col :span="24" class="hidden-sm-and-down">
          <el-row>
            <el-col :span="8" v-for="(item, index) in listItems" :key="index">
              <el-card :body-style="{ padding: '0 1.5rem' }">
                <p>
                  {{ item.value }}
                  <span>{{ item.label }}</span>
                </p>
              </el-card>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script setup lang="ts">
import "element-plus/theme-chalk/display.css";

interface ListItem {
  value: string;
  label: string;
}
interface ListItem {
  value: string;
  label: string;
}

const listItems: ListItem[] = [
  {
    value: "1%",
    label: "Trading fee",
  },
  {
    value: "$2 charged on first buy",
    label: "Token creation fee",
  },
  {
    value: "1.5 SOL, 0.5 token creator",
    label: "Migration fee returns to creator",
  },
];
</script>

<style lang="scss" scoped>
$background-color: var(--background-3-color);
$text-color: var(--text-3-color);
$font-size-title: 1.5rem;
$line-height-title: 1.88rem;
$font-size-subtitle: 1.13rem;
$margin-top-subtitle: 1.5rem;
$padding-body: 1.5rem;
$border-width: 1px;
$border-color: currentColor;

.session6 {
  background-color: $background-color;
  color: $text-color;
  // min-height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;

  .session-font {
    margin: 2rem;

    .el-card {
      background-color: $background-color;
      color: $text-color;
      border: 0;
      border-left: 1px solid;
      border-radius: 0;
      width: 22.13rem;
      margin: 3rem;

      .el-card__body {
        p {
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 1.88rem;
          margin: 0;
        }
        span {
          display: block;
          font-weight: 300;
          font-size: 1.13rem;
          margin-top: 1.5rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .session6 {
    .session-font {
      width: 22rem;
      margin: 2rem auto;

      li span {
        font-weight: 300;
        display: block;
        margin-left: 2ch; /* 2 characters indent */
        font-size: 12px;
      }
    }
  }
}
</style>

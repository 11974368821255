<template>
  <div class="session session3">
    <div class="section-font">
      <h1>Why MeMefun</h1>
      <ul>
        <li>
          Token creators enjoys portion of trading fee to incentivize
          high-profile creators
        </li>
        <li>
          Token holders enjoy platform revenue and vote for proposal accordingly
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
.session3 {
  position: relative;
  background-color: var(--background-2-color);
  color: var(--text-2-color);

  background-image: url("@/assets/img2.png"), url("@/assets/img3.png");
  background-repeat: no-repeat;
  background-size: 8.88rem 4.75rem, 8.88rem 5.06rem;
  background-position: 80% 0, 10% 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .section-font {
    width: 50vw;
    margin: 2rem;
    position: relative;
    z-index: 1; /* 确保文字在最上层 */

    li::before {
      color: #000; /* 设置小圆点的颜色为白色 */
    }
  }
}

@media only screen and (max-width: 991px) {
  .session3 {
    .section-font {
      width: 30rem;
      margin: 2rem auto;
    }
  }
}
</style>

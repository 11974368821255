<template>
  <div class="link-container">
    <el-link
      v-for="(img, index) in imgList"
      :href="img.url"
      :key="index"
      target="_blank"
      :underline="false"
    >
      <el-image :src="img.src" fit="cover" />
    </el-link>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

interface ImageItem {
  src: string;
  url: string;
}
const imgList = ref<ImageItem[]>([]);

async function loadImages() {
  const [iconX, iconM, iconT] = await Promise.all([
    import("@/assets/icon_X.png"),
    import("@/assets/icon_M.png"),
    import("@/assets/icon_T.png"),
  ]);

  imgList.value = [
    { src: (await iconX).default, url: "https://x.com/memefunme" },
    { src: (await iconM).default, url: "https://memefunme.medium.com" },
    { src: (await iconT).default, url: "https://t.me/+RsjVaSXaL-g4MTA1" },
  ];
}

onMounted(() => {
  loadImages();
});
</script>

<style lang="scss" scoped>
.link-container {
  display: flex; // 保持水平排列
  align-items: center; // 垂直居中
  justify-content: center; // 水平居中
  overflow-x: auto; // 允许水平滚动
  scroll-snap-type: x mandatory; // 启用滚动快照
  -webkit-overflow-scrolling: touch; // 平滑滚动

  &::-webkit-scrollbar {
    display: none; // 隐藏滚动条
  }
}

.el-link {
  display: inline-flex; // 使链接内的内容水平居中
  align-items: center;
  justify-content: center;
}

.el-image {
  max-width: 100px; // 设置最大宽度
  max-height: 100px; // 设置最大高度
}

@media only screen and (max-width: 991px) {
  .el-header {
    display: none;
  }
  .link-container {
    gap: 15px; // 链接之间的间距
  }
}

@media only screen and (min-width: 769px) {
  .link-container {
    gap: 100px; // 链接之间的间距
  }
}
</style>
